//navs
import navs from './../../assets-ui/js/components/navs/navs.js';
import './../../assets-ui/js/components/navs/tabs.js';
import switcher from './../../assets-ui/js/components/navs/switcher.js';

//forms
import './../../assets-ui/js/components/forms/forms.js';
import selects from './../../assets-ui/js/components/forms/selects.js';
import selectInput from './../../assets-ui/js/components/forms/select-input.js';

//dropdowns
import dropdown from './../../assets-ui/js/components/dropdowns/dropdown.js';
import tooltips from './../../assets-ui/js/components/dropdowns/tooltips.js';

//modals
import './../../assets-ui/js/components/modals/modal.js';

//other
import contentSlider from './../../assets-ui/js/components/content-slider.js';
import offCanvas from './../../assets-ui/js/components/off-canvas.js';
import './../../assets-ui/js/components/collapse.js';
import sticky from './../../assets-ui/js/components/sticky.js';
import './../../assets-ui/js/components/scroll-spay.js';

// site

import buttonHoverEffect from './components/button-hover-effect';
import mobileFooter from './components/mobile-footer';
import mobileNav from './components/mobile-nav';
import screenSlider from './components/screen-slider';
import passwordStrength from './components/password-strength';
import cookieBox from './components/cookie-box';


import customScrollbar from './components/custom-scrollbar';
import FAQList from './components/faq-list';
import pricingTable from './components/pricing-table';
import StickyNavbar from './components/sticky-navbar';
import animations from './components/animations/index.js';
import ModeSwitch from './components/mode-switch';
import other from './components/other';
import locationMapSwitcher from './components/location-map-switcher';
import dataTable from './components/data-table';

import sidebar from './components/resources/sidebar';
import toolbox from './components/resources/toolbox.js';

offCanvas.initJqueryPlugin();

$(document).ready(function () {

    //Nav
    navs.initDataSelectors();

    switcher.initDataSelectors();

    //Dropdown
    dropdown.initDataSelectors();

    //Tooltips
    tooltips.initDataSelectors();

    //Content Slider
    contentSlider.initDataSelectors();

    //Selects
    selects.initDataSelectors();
    selectInput.initDataSelectors();

    //Sticky
    sticky.initDataSelectors();

    //Off Canvas
    offCanvas.initDataSelectors();

    buttonHoverEffect.init();
    animations.init();
    mobileNav.init();
    mobileFooter.init();
    screenSlider.init();
    passwordStrength.init();
    cookieBox.init();

    customScrollbar.init();
    FAQList.init();
    pricingTable.init();
    StickyNavbar.init();
    ModeSwitch.init()
    other.init();
    locationMapSwitcher.init();
    dataTable.init();
    sidebar.init();
    toolbox.init();
});
